import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from '../../../../assets/images/operations/close.svg';
import { ReactComponent as LocationIcon } from '../../../../assets/images/operations/location.svg';
import { ReactComponent as UserIcon } from '../../../../assets/images/operations/user.svg';
import { SearchPanel } from '../../../../components/SearchPanel';
import { useClickAwayListener } from '../../../../hooks';
import { useGetAllEmployeesQuery } from '../../../../store/apis/employeesApi';
import { useGetLocationsQuery } from '../../../../store/apis/locationsApi';
import { operationsPageActions } from '../../../../store/slices';
import {
  employeesSortingPredicates,
  locationsSortingsPredicates
} from '../../../../utils/sortings';
import styles from '../../OperationsScreen.module.sass';

export const Search = () => {
  const { employeeFilter, locationFilter } = useSelector( state => state.operationsPage );

  const dispatch = useDispatch();

  const { data: locations } = useGetLocationsQuery();
  const { data: employees } = useGetAllEmployeesQuery();

  const ref = useRef( null );

  const [ term, setTerm ] = useState( '' );
  const [ isFocused, setFocused ] = useState( false );

  const setEmployeeFilter = filter =>
    dispatch( operationsPageActions.setEmployeeFilter( filter ) );
  const setLocationFilter = filter =>
    dispatch( operationsPageActions.setLocationFilter( filter ) );

  const toggleFocused = value => () => setFocused( value );

  useClickAwayListener( ref, () => {
    setFocused( false );
  } );

  const onEmployeeClick = employee => () => {
    setEmployeeFilter( employee );
  };
  const onLocationClick = location => () => {
    setLocationFilter( location );
  };

  const onClose = () => {
    setEmployeeFilter( undefined );
    setLocationFilter( undefined );
    setTerm( '' );
  };

  const visibleEmployees = useMemo( () => {
    if ( !term ) return employees || [];
    return ( employees || [] )
      .filter( employee =>
        employee.firstName
          .toLowerCase()
          .concat( employee.lastName.toLowerCase() )
          .includes( term.toLowerCase() )
      )
      .sort( employeesSortingPredicates.byLastName );
  }, [ employees, term ] );

  const visibleLocations = useMemo( () => {
    if ( !term ) return locations || [];
    return ( locations || [] )
      .filter( location => location.name.toLowerCase().includes( term.toLowerCase() ) )
      .sort( locationsSortingsPredicates.byName );
  }, [ locations, term ] );

  const renderResult = () => (
    <div className={styles['search-result']}>
      {employeeFilter ? <UserIcon /> : <LocationIcon />}
      {employeeFilter
        ? `${employeeFilter.lastName}, ${employeeFilter.firstName}`
        : locationFilter.name}
      <CloseIcon
        className={styles['close']}
        onClick={onClose}
      />
    </div>
  );

  return (
    <div className={styles['search-box']}>
      {employeeFilter || locationFilter ? (
        renderResult()
      ) : (
        <SearchPanel
          value={term}
          onChange={setTerm}
          className={styles['search']}
          placeholder={'Type here to search...'}
          searchRef={ref}
          onFocus={toggleFocused( true )}
        />
      )}

      {isFocused && term.length >= 3 && (
        <ul className={styles['search-results']}>
          {visibleEmployees.map( employee => (
            <li
              key={employee.id}
              onClick={onEmployeeClick( employee )}
            >
              <UserIcon />
              {employee.lastName}, {employee.firstName}
            </li>
          ) )}
          {visibleLocations.map( location => (
            <li
              key={location.id}
              onClick={onLocationClick( location )}
            >
              <LocationIcon />
              {location.name}
            </li>
          ) )}
        </ul>
      )}
    </div>
  );
};
