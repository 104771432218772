import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { concat, roundHalf } from '../../../../utils/utils';
import styles from '../../OperationsScreen.module.sass';

export const BadgeHoverBoxEvent = ( {
  event,
  children,
  employee,
  className
} ) => {
  const start = useMemo( () => DateTime.fromISO( event.start ), [ event ] );
  const end = useMemo( () => DateTime.fromISO( event.end ), [ event ] );
  const hoursDiff = useMemo( () => roundHalf( end.diff( start, 'hours' ).hours ) );

  const locationStyle = useMemo( () => {
    if ( employee ) {
      const offerStatus = event.JobOffer?.ReceivedEmployees.find(
        item => item.id === employee.id
      )?.SentEmployees.type;

      if ( offerStatus === 'accept' ) return;
      if ( offerStatus === 'rejected' )
        return {
          fontStyle: 'italic',
          textDecoration: 'line-through'
        };
      return { fontStyle: 'italic' };
    }
  }, [ event, employee ] );

  const render = () => (
    <>
      <p style={locationStyle}>
        {event.JobRole.Location.name} - {event.JobRole.name}
      </p>
      <p>
        <span>
          {start.toFormat( 'HH:mm' )} - {end.toFormat( 'HH:mm' )}
        </span>
        <span>{hoursDiff} hours</span>
      </p>
      {children}
    </>
  );

  return (
    <Link
      to={`/event-listing/${event.id}`}
      className={concat( styles['hover-event'], className )}
    >
      {render()}
    </Link>
  );
};
