import { forwardRef } from 'react';
import { RiCalendar2Line } from 'react-icons/ri';
import styles from '../../OperationsScreen.module.sass';

export const CalendarButton = forwardRef( ( { onClick }, ref ) => (
  <span
    onClick={onClick}
    ref={ref}
  >
    <RiCalendar2Line className={styles['button']} />
  </span>
) );
